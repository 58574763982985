import { ImageLoaderProps } from 'next/image';

export const imgOpLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  if (src.endsWith('svg')) {
    return encodeURI(transformImageUrltoBase(src));
  }

  if (quality) {
    return encodeURI(`${src}?format=webp&quality=${quality}&width=${width}`);
  } else {
    return encodeURI(`${src}?format=webp&width=${width}`);
  }
};

export const transformImageUrl = (url: String) => {
  if (url.includes('.svg')) {
    return encodeURI(transformImageUrltoBase(url));
  }

  return encodeURI(
    url
      .replace(
        'https://content.thedemiplane.com',
        process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED!
      )
      .replace(
        'https://content.demiplane.com',
        process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED!
      )
  );
};

export const transformImageUrltoBase = (url: String) => {
  return url
    .replace(
      'https://iceberg.thedemiplane.com',
      process.env.NEXT_PUBLIC_CONTENT_ENDPOINT!
    )
    .replace(
      'https://images.demiplane.com',
      process.env.NEXT_PUBLIC_CONTENT_ENDPOINT!
    );
};
