import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IsProductKeyWaveEntitledQueryVariables = Types.Exact<{
  sourceIds: Array<Types.Scalars['bigint']['input']> | Types.Scalars['bigint']['input'];
  earlyAccessEnabled: Types.Scalars['Boolean']['input'];
  preorderAccessEnabled: Types.Scalars['Boolean']['input'];
}>;


export type IsProductKeyWaveEntitledQuery = { __typename?: 'query_root', isProductKeyWaveEntitled?: { __typename?: 'IsProductKeyWaveEntitledResponse', error?: string | null, message?: string | null, success: boolean, data?: { __typename?: 'IsProductKeyWaveEntitledData', entitled: boolean } | null } | null };


export const IsProductKeyWaveEntitledDocument = gql`
    query isProductKeyWaveEntitled($sourceIds: [bigint!]!, $earlyAccessEnabled: Boolean!, $preorderAccessEnabled: Boolean!) {
  isProductKeyWaveEntitled(
    earlyAccessEnabled: $earlyAccessEnabled
    preorderAccessEnabled: $preorderAccessEnabled
    sourceIds: $sourceIds
  ) {
    data {
      entitled
    }
    error
    message
    success
  }
}
    `;

export function useIsProductKeyWaveEntitledQuery(options: Omit<Urql.UseQueryArgs<IsProductKeyWaveEntitledQueryVariables>, 'query'>) {
  return Urql.useQuery<IsProductKeyWaveEntitledQuery, IsProductKeyWaveEntitledQueryVariables>({ query: IsProductKeyWaveEntitledDocument, ...options });
};