import { createTheme } from '@mui/material/styles';
import { baseTheme } from './baseTheme';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    special: true;
    title: true;
  }
}

export const NexusTheme = createTheme(
  {
    ...baseTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1600,
        xl: 1900,
      },
    },
  },
  {
    palette: {
      primary: {
        main: '#00ABFF',
      },
      tertiary: {
        main: '#202020',
      },
      success: {
        dark: '#388e3c',
      },
      base: {
        base0: '#101014',
        base1: '#1B1C22',
        base2: '#272831',
        base3: '#32333D',
        base4: '#4A4D5F',
        base5: '#5F6774',
        base6: '#C1C4D2',
        base7: '#DBDEE4',
      },
      secondary: {
        main: '#393636',
        contrastText: '#000000',
      },
      accent: {
        accent0: '#58E490',
        accent1: '#FFD84D',
        accent2: '#FF5C5C',
      },
      whiteBg: {
        main: '#FFFFFF',
      },
      cancel: {
        main: '#687079',
        light: '#969ea8',
        dark: '#3d454d',
        contrastText: '#FFFFFF',
      },
      purchase: {
        main: '#58E490',
        light: '#90ffc1',
        dark: '#04b162',
        contrastText: '#FFFFFF',
      },
    },
    typography: {
      display1: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '64px',
        lineHeight: '62px',
        color: '#FFFFFF',
      },
      display2: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '52px',
        lineHeight: '50px',
        color: '#FFFFFF',
      },
      h1: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
        color: '#FFFFFF',
      },
      h2: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '30px',
        color: '#FFFFFF',
      },
      h3: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '26px',
        color: '#FFFFFF',
      },
      h4: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
      },
      body1: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#E3E7F0',
      },
      body2: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '30px',
        color: '#E3E7F0',
      },
      subtitle1: {
        fontFamily: 'Barlow',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#C1C4D2',
      },
      subtitle2: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#E3E7F0',
      },
      tag: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        letterSpacing: '1px',
        lineHeight: '16px',
        color: '#FFFFFF',
      },
      listingHeader: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#FFFFFF',
      },
      chapterMarker: {
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontSeight: 700,
        fontSize: '20px',
        lineHeight: '27px',
        letterSpacing: '8px',
        textTransform: 'uppercase',
        color: '#C1C4D2',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontFamily: 'Barlow',
            fontSize: '16px',
            width: '100%',
            color: '#FFFFFF',
            backgroundColor: 'transparent',
          },
          primary: {
            color: '#FFFFFF',
            backgroundColor: '#00ABFF',
            borderRadius: '6px',
          },
          secondary: {
            color: '#00ABFF',
            backgroundColor: '#FFFFFF',
          },
        },
        variants: [
          {
            props: { variant: 'cartLarge' },
            style: {
              textTransform: 'uppercase',
              background: '#58E490',
              color: '#FFFFFF',
            },
          },
          {
            props: { variant: 'cartSmall' },
            style: {
              color: '#00ABFF',
              background: '#FFFFFF',
            },
          },
          {
            props: { variant: 'reset' },
            style: {
              background: 'transparent',
              color: '#FFFFFF',
              border: '1px solid #4A4D5F',
            },
          },
        ],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            marginBottom: 0,
          },
        },
      },
    },
  }
);
